@media (max-width: 1699px) {}

@media (max-width: 1499px) {
    .imgSwiper-btns {
        top: 0rem;
    }
}

@media (max-width: 1366px) {
    .h1-l {
        font-size: 54px;
    }

    .testimonial .dl-icon {
        margin-left: 0;
    }

    .dl-roar {
        margin-top: 60px;
        background-size: 80%;
    }

    .dl-error {
        background-position: right bottom;
        background-size: 80%;
    }
}

@media (max-width: 1199px) {
    .line-break {
        display: initial;
    }

    .swiper {
        overflow: visible;
    }

    ._overflowUpto1199 {
        overflow: hidden;
    }

    .imgSwiper-btns {
        gap: 2rem;
        top: -2.5rem;
    }

    header .hidden-mobile ul._nav_items {
        gap: 2rem;
    }

    header.scrolled .dl-logo,
    header .dl-logo {
        width: 76px;
        overflow: hidden;
    }

    .h1-l {
        font-size: 46px;
    }

    .dl-error {
        background-size: 70%;
        margin-top: 60px;
    }

    .sticky-sub-nav li a {
        padding: 20px 28px;
    }

    .dl-job-card ul li {
        margin-right: 10px;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .dl-job-card i {
        flex: 0 0 80px;
    }

    .dl-block-cta a {
        padding: 70px;
    }

    .dl-team-inspire-card h3 {
        font-size: 34px;
    }

    .dl-team-inspire-card p {
        font-size: 18px;
    }

    /***/
    .dl-process-steps{
        flex-wrap: wrap;
    }

    .dl-process-steps >div{
        width: 33%;
    }
}

@media screen and (max-width:1024px){
    .blogSwiper.swiper .swiper-button-next,
    .blogSwiper.swiper .swiper-button-prev{
        display: none;
    }
    .blogSwiper.swiper .swiper-pagination {
        display: block;
        bottom: 0px !important;
    }
    .blogSwiper.swiper{
        padding-top: 0;
        padding-bottom: 30px;
    }
}

@media (max-width: 991px) {
    .hidden-dlsm {
        display: none !important;
    }

    header .hidden-mobile ul._nav_items {
        gap: .5rem;
    }

    .visible-dlsm {
        display: inline-block !important;
    }

    .dlsm-mB20 {
        margin-bottom: 20px;
    }

    .dlsm-center {
        text-align: center;
    }

    .dlsm-pT0 {
        padding-top: 0;
    }

    .dlsm-flex-col {
        flex-direction: column;
    }

    .dlsm-flex-wrap {
        flex-wrap: wrap;
    }

    .dl-job-card i {
        flex: 0 0 100%;
    }

    .dlsm-flex-jcc {
        justify-content: center;
    }

    .dlsm-flex-ac {
        align-items: center;
    }

    .dl-roar {
        background-size: 50%;
        margin-top: 50px;
    }

    .nav-up {
        top: 0;
    }

    header .logo {
        width: 190px;
    }

    header.scrolled .dl-logo,
    header .dl-logo {
        width: 65px;
        overflow: hidden;
    }

    header nav ul li a {
        padding: 15px 20px;
    }

    .dl-block, .dl-service-card, #values, #models.dl-scroll-block {
        padding: 100px 0;
    }
    
    .dl-block.dl-scroll-block{
        padding: 0;
    }

    .dl-block-aid-media {
        padding-left: 4vw;
        padding-right: 4vw;
        padding-top: 0;
    }

    .dlsm-jcc {
        justify-content: center;
    }

    .dlsm-mT20 {
        margin-top: 20px;
    }

    h1,
    .font-62 {
        font-size: 48px;
    }

    h2,
    h3 {
        font-size: 44px;
    }

    hr {
        margin: 100px 0;
    }

    .dl-page-banner {
        padding: 150px 0 80px;
    }

    .dlsm-mT0 {
        margin-top: 0;
    }

    .dlsm-mB30 {
        margin-bottom: 30px;
    }

    .sticky-sub-nav {
        display: none;
    }

    .flex-grid {
        flex: 1 1 50%;
    }

    .dl-block-cta svg {
        width: 35px;
        height: 35px;
    }

    .dl-block-cta a {
        padding: 50px;
    }

    .dlxs-flex-col {
        flex-direction: column;
    }

    .dlsm-p0 {
        padding: 0;
    }

    .dl-job-card {
        padding: 35px 0;
    }

    .dl-process-steps.hiring .card-title {
        font-size: 21px;
    }

    .dl-process-steps.hiring svg {
        width: 90px;
        height: 90px;
    }

    .dl-job-banner:after {
        top: 100px;
        right: -60px;
        background-size: 80%;
    }

    .p60 {
        padding: 30px
    }

    /***/
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        right: 60px !important;
    }

    .hamburger {
        display: inline-block;
    }
}

@media (min-width: 825px) {
    .hamburger {
        display: none;
    }
}

@media (max-width: 825px) {
    .dl-mobile-nav {
        position: fixed;
        background-color: var(--brand-color);
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        top: 0;
        left: 0;
        padding: 0 15px;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }

    .hidden-mobile {
        display: none;
    }

    .dl-process-steps .card-title {
        font-size: 22px;
    }

    .dl-process-steps>svg {
        width: 80px;
        height: 80px;
    }
}

@media (max-width:768px) {

    .mySwiper .swiper-button-prev,
    .mySwiper .swiper-button-next {
        position: relative;
        top: 14px !important;
        width: 100% !important;
        margin-top: 0 !important;
        float: left !important;
        right: 0px !important;
        display: none;
    }

    .mySwiper .swiper-button-next {
        margin-top: -44px !important;
        right: -44px !important;
    }

    .mySwiper .swiper-button-prev {
        left: -30px !important;
    }

    .swiper.mySwiper {
        padding-top: 0;
        padding-bottom: 30px;
    }

    .mySwiper .swiper-pagination {
        display: block;
        bottom: 0px !important;
    }

    .swiper-slide-active {
        opacity: 1 !important;
        position: static !important;
        z-index: 1 !important;
    }

    /* .swiper-wrapper {
        transform: translate(0);
    } */

    .swiper-slide {
        width: 100% !important;
        z-index: -1;
        position: absolute;
        height: 100%;
        opacity: 0;
        transform: scale(1) !important;
        transition: opacity 0.3s ease !important;
    }
    .dark-bg-slider .swiper-pagination-bullet{
        background-color: #ccc !important;
    }
    .dark-bg-slider .swiper-pagination-bullet-active{
        background-color: #fff !important;
    }

}

@media (max-width: 767px) {
    .dl-error {
        background-size: 40%;
        margin-top: 60px;
    }

    ._we-are_typo {
        height: 12rem;
    }

    .__we-are_section .mT40 {
        margin-top: 1.5rem;
    }

    .dl-error h2 {
        font-size: 100px;
    }

    .dlxs-mT20 {
        margin-top: 20px;
    }

    .dlxs-mT10 {
        margin-top: 10px;
    }

    .dlxs-mT5 {
        margin-top: 5px;
    }

    .dl-block-cta.dlxs-p0,
    .dlxs-p0 {
        padding: 0;
    }

    .h1-l {
        font-size: 48px;
    }

    .dl-roar .dl-social {
        position: initial;
        margin-top: 20px;
        width: 100%;
        float: left;
    }

    .dlxs-mB20 {
        margin-bottom: 20px;
    }

    .dlxs-mB10 {
        margin-bottom: 10px;
    }

    .dlxs-mB0 {
        margin-bottom: 0;
    }

    .dl-team-inspire-card .p40 {
        padding: 15px 0px 25px;
    }

    .dlxs-m0 {
        margin: 0;
    }

    .dlxs-center {
        text-align: center;
    }

    footer .dl-card-list ul li {
        margin-bottom: 10px;
    }

    .dl-block-sm {
        padding-top: 40px;
    }

    .dl-block,
    footer.dl-block, .dl-service-card, #values, #models.dl-scroll-block{
        padding: 70px 0;
    }
    .dl-block.dl-scroll-block{
        padding: 0;
    }

    .dl-block-aid-media {
        padding-top: 0;
    }

    .font-l {
        font-size: 20px;
    }

    h1,
    .font-62 {
        font-size: 42px;
    }

    h2,
    h3 {
        font-size: 40px;
    }

    h4 {
        font-size: 28px;
    }

    h5 {
        font-size: 24px;
    }

    .dl-button {
        padding: 16px 27px;
        font-size: 17px;
    }

    .dl-card-list .title {
        margin-top: 5px;
    }

    .dl-card-list ul li {
        margin-bottom: 5px;
    }

    hr {
        margin: 80px 0;
    }

    .dl-process-steps {
        flex-wrap: wrap;
    }

    .dl-process-steps > div {
        flex: 50%;
    }

    .testimonial ul h6 {
        font-size: 28px;
    }

    .testimonial ul p {
        font-size: 18px;
    }

    .testimonial .content {
        font-size: 28px;
        line-height: 38px;
    }

    .dl-page-banner {
        padding: 140px 0 70px;
    }

    .dl-block-aid.dl-page-banner {
        padding: 140px 0 60px;
    }

    .dl-card-stat,
    .dl-card-service {
        opacity: 1;
    }

    .flex-grid {
        flex: 1 1 100%;
    }

    .flex-grid.dl-icon-card {
        padding: 15px 0px;
    }

    .dl-job-banner:after {
        top: 100px;
        right: -60px;
        background-size: 80%;
    }

    .dl-breadcrumb {
        bottom: 10px;
    }

    .dl-breadcrumb .arrow svg {
        transform: rotate(-180deg);
    }

    .dl-breadcrumb li+li:before {
        content: "";
    }

    .dl-team-card img {
        margin: auto;
        width: 100%;
    }

    .dlxs-flex-ac {
        align-items: center;
    }

    .dl-initiatives ul li img {
        height: 70px;
        margin-bottom: 15px;
    }

    .dl-accordian ul h5 {
        font-size: 24px;
    }

    .dl-accordian ul li {
        padding: 30px 0;
    }

    .dl-check-buttons .dl-checkbox label {
        padding: 9px 20px;
        font-size: 17px;
    }

    .dl-contact-form .line-break {
        display: block;
    }

    .dl-checkbox {
        margin-bottom: 10px;
        margin-right: 5px;
    }

    .dl-contact-form hr {
        border-color: var(--border-color-d);
    }

    .privacy-points h2 {
        font-size: 36px;
        line-height: 40px;
    }
    
    .privacy-points h3 {
        font-size: 26px;
        line-height: 30px;
    }
    
    .privacy-policy p, .privacy-policy ul li {
        font-size: 18px;
        line-height: 24px;
    }
}

@media (max-width: 600px) {
    .dlxss-center {
        text-align: center;
    }

    .font-xl {
        font-size: 26px;
    }

    .font-l {
        font-size: 20px;
    }

    .font-n {
        font-size: 18px;
    }

    .dlxss-mB20 {
        margin-bottom: 20px;
    }

    .dlxss-flex-col {
        flex-direction: column;
    }

    .dlxss-p0 {
        padding: 0;
    }

    .dlxss-m0 {
        margin: 0;
    }

    .dlxss-pB20 {
        padding-bottom: 20px;
    }

    .dlxss-pB10 {
        padding-bottom: 10px;
    }

    .dlxss-pB0 {
        padding-bottom: 0;
    }

    .dlxss-mB10 {
        margin-bottom: 10px;
    }

    .dlxss-pT0 {
        padding-Top: 0;
    }

    footer .dl-foot-contact img {
        width: 90px;
    }

    h2,
    h3 {
        font-size: 36px;
    }

    .dl-button i {
        display: none;
    }

    hr {
        margin: 60px 0;
    }

    .dl-process-steps > div {
        flex: 100%;
    }

    .testimonial .content {
        font-size: 24px;
        line-height: 34px;
    }

    .dl-card-stat h5,
    .dl-card-service h5 {
        font-weight: normal;
        font-size: 20px;
    }

    .dl-card-stat,
    .dl-card-service {
        padding: 16px 0;
    }

    .dlxss-border-none {
        border: none;
    }

    .dl-card-service .dlsm-bold {
        font-weight: 600;
        font-size: 24px;
    }

    .dl-icon-card .dl-icon svg {
        width: 110px;
        height: 110px;
    }

    .dl-job-banner:after {
        top: 160px;
        right: -60px;
        height: 200px;
        width: 200px;
    }

    .dl-button.dlxss-block,
    .dlxss-block {
        display: block;
        width: 100%;
        text-align: center;
    }

    .dl-job-summary-card {
        padding: 20px;
    }

    .dl-accordian ul li {
        padding: 25px 0;
    }

    .dl-accordian ul h5 {
        font-size: 22px;
        line-height: normal;
    }
}

@media (min-width: 600px) {
    .visible-dlxss {
        display: none;
    }
}

@media (max-width: 599px) {
    .hidden-dlxss {
        display: none !important;
    }

    ._paddingRight0Above599 {
        padding-right: 10px;
    }

    ._about.dl-initiatives ul {
        gap: 30px;
    }

    ._about.dl-initiatives ul li {
        margin-right: 0;
    }

    ._about.dl-initiatives ul li img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }

    .visible-dlxss {
        display: inline-block !important;
    }

    .dl-block-cta a {
        padding: 35px 25px;
    }

    .dl-block-cta p {
        font-size: 18px;
        line-height: normal;
    }

    .dl-block-cta .dl-h4-title {
        font-size: 24px;
    }

    .swiper {
        height: 310px;
    }

    .swiper-slide.swiper-slide-active {
        width: 100% !important;
        /* margin-left: -17vw; */
    }

    .imgSwiper-btns {
        position: static;
        gap: 4.3rem;
        margin-top: 2rem;
        display: flex;
        height: 4.4rem;
        justify-content: end;
    }

    ._slider_btn {
        margin-top: 2.5rem;
    }

    .imgSwiper-btns .swiper-button-next,
    .imgSwiper-btns .swiper-button-prev {
        position: static;
        display: inline-block;
        margin: 0;
    }

    .swiper {
        height: fit-content;
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: auto !important;
        bottom: 0px;
    }

    .h1-l,
    h1,
    .font-62 {
        font-size: 40px;
    }

    .dl-page-banner h1 {
        font-size: 36px;
    }

    .dl-page-banner h1.dl-page-title {
        font-size: 19px;
    }
}

@media screen and (max-width:575px){
    .privacy-points h2 {
        font-size: 30px;
        line-height: 36px;
        gap: 10px;
        margin-bottom: 20px;
    }
    .bottom-txt p{
        font-size: 18px;
    }
    .table {
        width: 100%;
        overflow-x: auto;
    }
    .table table {
        white-space: nowrap;
    }
}

@media (max-width: 500px) {
    .imgSwiper-btns {
        justify-content: space-evenly;
    }
}

@media (max-width: 399px) {
    ._about.dl-initiatives ul {
        gap: unset;
        flex-direction: column;
    }

    ._about.dl-initiatives ul li {
        margin-right: 0;
    }

    ._about.dl-initiatives ul li img {
        width: auto;
        height: 70px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 340px) {
    ._we-are_typo {
        height: 17rem;
    }

    ._career_page.dl-block-cta a {
        padding: 35px 10px;
    }

    ._career_page.dl-block-cta .dl-h4-title {
        font-size: 2rem;
    }

    ._we-are_typo h3 {
        white-space: nowrap;
    }

    ._we-are_typo span {
        white-space: normal;
        display: block;
    }
}