/* The Brand Design Partner */
body.brand-partner {
    cursor: none;
}

.brand-partner a {
    cursor: none !important;
}

.brand-partner .dl-button{
    cursor: none;
}

.brand-partner .custom-cursor {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    mix-blend-mode: normal;
    width: 40px;
    height: 40px;
    /* background-color: white; */
    background-image: url('../img/Vector.svg');
    background-repeat: no-repeat;
    background-size: contain;
    /* transition: transform 350ms ease; */
    transform: translate(-5%, -40%) scale(1);
    z-index: 1111;
}

.brand-partner .custom-cursor.cursor-hover {
    background-image: url('../img/hand-icon.svg');
}

.brand-partner .modal-content input,
.brand-partner .modal-content select,
.brand-partner .modal-content textarea,
.brand-partner .modal-content button {
    cursor: none;
}

.brand-partner .custom-cursor::before {
    content: "";
    position: relative;
    width: 350px;
    height: 350px;
    background-image: url('../img/cursor11.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    /* background: red; */
    top: -150px;
    transform: scale(0.7);
    left: -150px;
}

.brand-partner .cursor-without-shadow {
    mix-blend-mode: difference;
    background-image: url('../img/Vector.svg');
    width: 40px;
    height: 40px;
}

.brand-partner .cursor-without-shadow.custom-cursor::before {
    width: 0;
    height: 0;
}

.brand-partner .custom-cursor--link {
    transform: translate(-50%, -50%) scale(1.4);
}

.brand-partner .custom-cursor--link::before {
    transform: scale(0) !important;
}


/***common css starts***/
.mB90 {
    margin-bottom: 90px;
}

._brand_design_partner p {
    color: #999;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
    opacity: 1;
}

/* ._brand_design_partner p span {
    font-weight: 700;
} */

._brand_design_partner h2 {
    color: var(--White, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin: 0;
}

._brand_design_partner .text-center {
    text-align: center;
}

._brand_design_partner .section-info {
    gap: 2.8rem;
    display: flex;
    flex-direction: column;
}

._brand_design_partner .section-padding {
    padding: 14rem 0;
}

.dl-wrapper._brand_design_partner {
    background: var(--Black, #000);
}

/***common css ends***/

/***new css**/

._brand_design_partner header {
    padding-top: 17px;
    padding-bottom: 19px;
}

._brand_design_partner ._hero {
    margin-top: 110px;
    padding-top: 72px;
    padding-bottom: 0px;
    overflow: hidden;
    background: var(--Black, #000);
}

._brand_design_partner section .dl-container {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

._brand_design_partner ._hero .dl-container {
    padding-bottom: 90px;
}

._brand_design_partner ._hero_brand {
    color: #999;
    font-size: 20px;
    font-weight: 500;
    line-height: 70px;
    text-align: center;
    font-style: normal;
    font-family: Poppins;
    letter-spacing: 4px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

._brand_design_partner ._hero_tagline {
    display: flex;
    justify-content: center;
}

._hero_tagline_text {
    font-weight: 700;
    font-size: 8.4rem;
    min-width: 115rem;
    text-align: center;
    word-spacing: -50px;
    line-height: 6.3rem;
    letter-spacing: 40px;
    font-family: Poppins;
    color: rgba(250, 250, 250, 0.3);
}

._hero_tagline_text span {
    color: rgba(250, 250, 250, 1);
}

._brand_design_partner ._hero_media {
    margin-top: 11rem;
    position:relative;
}

._brand_design_partner ._hero_media ._hero_media_file {
    /* transform: scale(1.2); */
    width: 100%;
    height: 657px;
    object-fit: contain;
}

a#videoThumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

a#videoThumbnail img {
    width: 100%;
    max-width: 240px;
    height: 240px;
    animation: play 1.2s infinite;
}

@keyframes play {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
}

#videoModal .modal-content {
    width: 100%;
    height: 100%;
    background: rgb(15 15 15 / 85%);
}

#modalVideoContainer {
    width: 100%;
    height: 90%;
    max-width: 90%;
    margin: auto;
    background: #000;
}

#modalVideoContainer video {
    width: 100%;
    height: 100%;
    display: block;
}

.brand-partner video ::-webkit-media-controls-fullscreen-button,
.brand-partner video::-webkit-media-controls-play-button,
.brand-partner video::-webkit-media-controls-timeline,
.brand-partner video::-webkit-media-controls-current-time-display,
.brand-partner video::-webkit-media-controls-time-remaining-display,
.brand-partner video::-webkit-media-controls-mute-button,
.brand-partner video::-webkit-media-controls-toggle-closed-captions-button,
.brand-partner video::-webkit-full-page-media::-webkit-media-controls-panel,
.brand-partner video::-webkit-media-controls-toggle-closed-captions-button,
.brand-partner video::-webkit-media-text-track-container,
.brand-partner video::-webkit-media-controls-toggle-closed-captions-button,
.brand-partner video::-webkit-media-controls-volume-slider,
.brand-partner video::-webkit-media-controls-toggle-closed-captions-button,
.brand-partner video::-webkit-media-controls-panel,
.brand-partner video::-webkit-media-controls
 {
    cursor: none !important;
}


.close-video {
    display: block;
    color: #fff;
    text-align: right;
    max-width: 95%;
    margin: auto auto 10px;
}

.text-center {
    text-align: center;
}

footer ._footer_columns {
    display: flex;
    justify-content: space-between;
}

._hero_tagline_text ._shadow-left {
    text-shadow: -5px 0px 1px rgba(250, 250, 250, 0.3);
}

._hero_tagline_text-brand {
    width: 46.3rem;
}

._hero_tagline_text-design {
    width: 51.5rem;
    margin-left: -49px;
    letter-spacing: 40px;
    text-shadow: -3px 0px 1px rgba(250, 250, 250, 0.3);
}

._hero_tagline_text-partner {
    width: 73.4rem;
    margin-left: 63px;
    letter-spacing: 40px;
    text-shadow: -4px 0px 1px rgba(250, 250, 250, 0.3);
}

/* ._hero_tagline_text span {
    width: 100%;
    display: block;
    position: absolute;
    text-align: center;
} */


._brand_design_partner ._our_partner_program_wrapper h3 {
    margin: 0;
}

._brand_design_partner ._our_grid_items_row {
    gap: 3rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

._brand_design_partner ._our_grid_items_row ._our_grid_item {
    width: 48%;
    gap: 2rem;
    display: flex;
    text-align: center;
    align-items: center;
    background: #070707;
    flex-direction: column;
    padding: 3rem 3rem 5rem;
}

._brand_design_partner ._our_grid_item figure svg {
    width: auto;
    height: 128px;
}

._brand_design_partner ._our_grid_item h6 {
    margin: 0;
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    font-style: normal;
    font-family: Poppins;
}



@keyframes shine {
    from {
        -webkit-mask-position: 200%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}

._brand_design_partner .our-team img {
    width: 100%;
    height: auto;
}

._our_grid_item img {
    width: 150px;
}


.glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.glitch {
    color: black;
    text-transform: upercase;
    position: relative;
    display: inline-block;
}

.d-inline-block {
    display: inline-block;
}

.glitch::before,
.glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch::before {
    left: 2px;
    text-shadow: -2px 0 #999;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
    left: -2px;
    text-shadow: -2px 0 #bbb;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

/****characteristics*****/
.characteristics ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    list-style: none;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.characteristics ul li {
    color: rgb(255 255 255 / 70%);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 15px 20px;
    border: 1px solid rgb(214 214 214 / 36%);
    display: flex;
    align-items: center;
}

.characteristics ul li img {
    margin-right: 5px;
}

.our-value .characteristics ul {
    flex-flow: wrap-reverse;
    flex-direction: row-reverse;
}

/*****what-we-do***/
.what-we-do-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.what-we-do-row>div {
    width: 50%;
}

.what-we-do-row h2 {
    text-align: left;
}

.what-we-do-row p {
    max-width: 481px;
}

/****brand partner***/
.brand-partner-logos ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px 114px;
    margin: 0;
    padding: 0;
}

/***our-values****/
._brand_design_partner .our-customer-values ._our_grid_items_row ._our_grid_item {
    width: 31%;
}

/*****our brands and initiatives****/
.our-brand-logos ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    margin: 0;
    padding: 0;
}

/****faqs****/
._brand_design_partner .dl-accordian ul li {
    border-color: rgb(214 214 214 / 30%);
}

._brand_design_partner .dl-accordian ul h5 {
    color: #fff;
}

._brand_design_partner .dl-accordian ul svg path {
    fill: #fff;
}

._brand_design_partner .dl-accordian ul p,
._brand_design_partner .dl-accordian ul p a {
    color: #999;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

._brand_design_partner .dl-accordian ul p a {
    border-bottom: 1px solid #999;
}

._brand_design_partner .dl-accordian ul p a:hover {
    text-decoration: none;
    border: 0;
}

._brand_design_partner .dl-foot-contact p {
    margin-top: 20px;
}

._brand_design_partner .media-wrapper dotlottie-player {
    width: 100% !important;
    height: auto !important;
}

@-webkit-keyframes glitch-anim {
    0% {
        clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
        clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
        clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
        clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
        clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
        clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
        clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
        clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
        clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
        clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
        clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
        clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
        clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
        clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
        clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
        clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
        clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
        clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
        clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
        clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
        clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
        clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
        clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
        clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
        clip: rect(101px, 9999999px, 72px, 0);
    }
}

@-o-keyframes glitch-anim {
    0% {
        clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
        clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
        clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
        clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
        clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
        clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
        clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
        clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
        clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
        clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
        clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
        clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
        clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
        clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
        clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
        clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
        clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
        clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
        clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
        clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
        clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
        clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
        clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
        clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
        clip: rect(101px, 9999999px, 72px, 0);
    }
}

@-moz-keyframes glitch-anim {
    0% {
        clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
        clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
        clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
        clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
        clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
        clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
        clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
        clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
        clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
        clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
        clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
        clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
        clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
        clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
        clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
        clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
        clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
        clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
        clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
        clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
        clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
        clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
        clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
        clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
        clip: rect(101px, 9999999px, 72px, 0);
    }
}

@keyframes glitch-anim {
    0% {
        clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
        clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
        clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
        clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
        clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
        clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
        clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
        clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
        clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
        clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
        clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
        clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
        clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
        clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
        clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
        clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
        clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
        clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
        clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
        clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
        clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
        clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
        clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
        clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
        clip: rect(101px, 9999999px, 72px, 0);
    }
}

@-webkit-keyframes glitch-anim-2 {
    6.66666667% {
        clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
        clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
        clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
        clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
        clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
        clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
        clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
        clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
        clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
        clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
        clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
        clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
        clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
        clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
        clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
        clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
        clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
        clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
        clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
        clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
        clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
        clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
        clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
        clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
        clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
        clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
        clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
        clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
        clip: rect(5px, 9999999px, 14px, 0);
    }
}

@-o-keyframes glitch-anim-2 {
    6.66666667% {
        clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
        clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
        clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
        clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
        clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
        clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
        clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
        clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
        clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
        clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
        clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
        clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
        clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
        clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
        clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
        clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
        clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
        clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
        clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
        clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
        clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
        clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
        clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
        clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
        clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
        clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
        clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
        clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
        clip: rect(5px, 9999999px, 14px, 0);
    }
}

@-moz-keyframes glitch-anim-2 {
    6.66666667% {
        clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
        clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
        clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
        clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
        clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
        clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
        clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
        clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
        clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
        clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
        clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
        clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
        clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
        clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
        clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
        clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
        clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
        clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
        clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
        clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
        clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
        clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
        clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
        clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
        clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
        clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
        clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
        clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
        clip: rect(5px, 9999999px, 14px, 0);
    }
}

@keyframes glitch-anim-2 {
    6.66666667% {
        clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
        clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
        clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
        clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
        clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
        clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
        clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
        clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
        clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
        clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
        clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
        clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
        clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
        clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
        clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
        clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
        clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
        clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
        clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
        clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
        clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
        clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
        clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
        clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
        clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
        clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
        clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
        clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
        clip: rect(5px, 9999999px, 14px, 0);
    }
}

/******media css for BDP******/
@media screen and (max-width: 1200px) {

    .section-info {
        gap: 2rem;
        padding: 0;
    }
}

@media screen and (max-width:1024px) {
    body {
        cursor: auto !important;
    }

    .custom-cursor {
        display: none;
    }

    a {
        cursor: pointer !important;
    }
    ._brand_design_partner header .dl-logo {
        width: 6.5rem;
    }

    ._brand_design_partner header.scrolled .dl-logo {
        width: 6.5rem !important;
    }

    ._brand_design_partner header .logo {
        height: 58px;
        width: auto;
    }

    ._brand_design_partner ._hero {
        overflow: hidden;
        padding-top: 10rem;
        padding-bottom: 10rem;
        border-bottom: 1px solid var(--black200);
    }

    ._brand_design_partner ._hero .dl-container {
        padding-bottom: 0;
        border-bottom: 0;
    }

    ._brand_design_partner ._hero_brand {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }

    ._hero_tagline {
        transform: scale(1);
    }

    ._hero_tagline_text {
        min-width: unset;
        width: 100%;
        font-size: 6.1rem;
        letter-spacing: 10px;
        line-height: 5.1rem;
        word-spacing: unset;
    }

    ._hero_tagline_text span:first-child {
        display: inline-block;
        width: fit-content;
    }

    ._hero_tagline_text span {
        margin: 0;
        width: 100%;
        display: block;
        word-spacing: -20px;
        letter-spacing: unset;
    }

    span._hero_tagline_text-partner {
        margin-left: 8px;
    }


    ._brand_design_partner ._hero_media ._hero_media_file {
        margin-top: 11rem;
        height: auto;
    }

    ._brand_design_partner h2 {
        font-size: 3.6rem;
        line-height: 43.2px;
    }

    ._brand_design_partner footer ._footer_columns {
        gap: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    ._brand_design_partner footer nav ul li .link {
        padding: 0;
    }

    ._brand_design_partner ._roll-on_wrapper ._dl_location_info {
        justify-content: left;
    }

    ._brand_design_partner .dl-foot-contact ul {
        justify-content: left;
    }

    ._brand_design_partner .dl-card-list .title {
        margin-top: 0;
    }

    .section-info {
        gap: 1rem;
    }

    ._brand_design_partner ._our_grid_items_row {
        gap: 2rem;
    }

    ._brand_design_partner .section-padding {
        padding: 10rem 2rem;
    }

    ._brand_design_partner .our-customer-values ._our_grid_items_row ._our_grid_item {
        width: 48%;
    }
    #modalVideoContainer{
        height: auto;
    }
}

@media (max-width: 991px) {
    ._brand_design_partner .nav-up {
        top: 0;
    }

    ._brand_design_partner .hamburger {
        top: 21px;
    }

    .our-brand-logos ul li {
        width: 40%;
    }

    .brand-partner-logos ul li img,
    .our-brand-logos ul li img {
        width: 100%;
    }

    .our-brand-logos ul {
        justify-content: space-between;
    }

    ._brand_design_partner ._our_grid_items_row ._our_grid_item {
        width: 100%;
    }

    .what-we-do-row>div {
        width: 100%;
    }

    .what-we-do-row h2,
    .what-we-do-row p {
        text-align: center;
    }

    .what-we-do-row p {
        max-width: 100%;
    }

    .dl-accordian ul h5 {
        font-size: 24px;
    }

    ._brand_design_partner ._hero_media{
        margin-top: 0;
    }
}

@media screen and (max-width:768px) {
    ._brand_design_partner header .dl-logo {
        width: 6.5rem;
    }

    ._brand_design_partner header.scrolled .dl-logo {
        width: 6.5rem !important;
    }

    ._brand_design_partner header .logo {
        height: 58px;
        width: auto;
    }

    ._brand_design_partner ._hero {
        overflow: hidden;
        padding-bottom: 7rem;
        border-bottom: 1px solid var(--black200);
    }

    ._hero .dl-container {
        border-bottom: 0;
    }

    ._brand_design_partner ._hero_brand {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }

    ._hero_tagline {
        transform: scale(1);
    }

    ._hero_tagline_text {
        min-width: unset;
        width: 100%;
        font-size: 5.1rem;
        letter-spacing: 10px;
        line-height: 4.5rem;
        word-spacing: unset;
    }

    ._hero_tagline_text span:first-child {
        display: inline-block;
    }

    ._hero_tagline_text span {
        margin: 0;
        width: 100%;
        display: block;
        word-spacing: -20px;
        letter-spacing: unset;
    }

    span._hero_tagline_text-partner {
        margin-left: 8px;
    }

    ._brand_design_partner p {
        font-size: 18px;
        line-height: 25px;
    }

    ._brand_design_partner ._hero_media ._hero_media_file {
        margin-top: 12rem;
        height: auto;
    }

    ._brand_design_partner h2 {
        font-size: 3.6rem;
        line-height: 43.2px;
    }

    footer ._footer_columns {
        gap: 1rem;
        display: grid;
    }

    ._brand_design_partner ._roll-on_wrapper ._dl_location_info {
        justify-content: center;
    }

    ._brand_design_partner .dl-foot-contact ul {
        justify-content: center;
    }

    ._brand_design_partner ._our_partner_program_desc {
        font-size: 2rem;
    }

    ._brand_design_partner .our-customer-values ._our_grid_items_row ._our_grid_item {
        width: 100%;
    }

    ._brand_design_partner .section-padding {
        padding: 8rem 2rem;
    }

    .brand-partner-logos ul li {
        width: 39%;
    }

    .brand-partner-logos ul {
        gap: 30px;
    }

    .characteristics ul li {
        width: 100%;
        max-width: 80%;
    }

    .characteristics ul li img {
        margin-right: 10px;
    }

    ._brand_design_partner header .dl-logo {
        overflow: hidden;
    }
}

@media screen and (max-width:575px) {
    .our-brand-logos ul li {
        width: 100%;
        max-width: 60%;
        margin: auto;
    }

    .characteristics ul li {
        width: 100%;
        max-width: 100%;
    }

    ._brand_design_partner .our-customer-values ._our_grid_items_row ._our_grid_item,
    ._brand_design_partner ._our_grid_items_row ._our_grid_item {
        width: 100%;
        padding: 1.5rem 1.5rem 3rem;
    }
}

@media screen and (max-width:480px) {
    ._brand_design_partner header .dl-logo {
        width: 6.5rem;
    }

    ._brand_design_partner header.scrolled .dl-logo {
        width: 6.5rem !important;
    }

    ._brand_design_partner header .logo {
        height: 58px;
        width: auto;
    }

    ._brand_design_partner ._hero {
        overflow: hidden;
        padding-top: 6rem;
        padding-bottom: 7rem;
        border-bottom: 1px solid var(--black200);
    }

    ._hero .dl-container {
        border-bottom: 0;
    }

    ._brand_design_partner ._hero_brand {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }

    ._hero_tagline {
        transform: scale(1);
    }

    ._hero_tagline_text {
        min-width: unset;
        width: 100%;
        font-size: 4.1rem;
        letter-spacing: 10px;
        line-height: 3.5rem;
        word-spacing: unset;
    }

    ._hero_tagline_text span {
        margin: 0;
        width: 100%;
        display: block;
        word-spacing: -20px;
        letter-spacing: unset;
    }

    span._hero_tagline_text-partner {
        margin-left: 8px;
    }

    ._brand_design_partner p {
        font-size: 18px;
        line-height: 25px;
    }

    ._brand_design_partner ._hero_media ._hero_media_file {
        height: auto;
    }

    ._brand_design_partner h2 {
        font-size: 3.6rem;
        line-height: 43.2px;
    }

    ._brand_design_partner footer ._footer_columns {
        display: grid;
    }

    ._brand_design_partner h2 {
        font-size: 3.2rem;
        line-height: 48px;
    }

    ._brand_design_partner .section-info {
        gap: 2rem;
    }

    ._brand_design_partner .dl-accordian ul h5 {
        font-size: 20px;
    }

    ._brand_design_partner .dl-accordian ul p,
    ._brand_design_partner .dl-accordian ul p a {
        font-size: 18px;
        line-height: 25px;
    }
}