.modal {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.modal .modal-body input {
    z-index: 1;
}

.modal {
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgb(0 0 0 / 60%);
}

.modal .modal-content {
    outline: 0;
    padding: 5rem;
    position: relative;
    border-radius: 0px;
    background-color: #ffffff;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.modal .modal-head {
    row-gap: 5px;
    display: flex;
    column-gap: 4rem;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modal-close-wrapper {
    display: flex;
    justify-content: flex-end;
}

.modal .modal-head button {
    display: flex;
    padding: unset;
    width: fit-content;
    height: fit-content;
}

.modal .modal-head svg {
    width: 4rem;
    height: auto;
}

.modal-head .modal-headline {
    margin: unset;
    font-size: 3rem;
    line-height: 42px;
}

.modal-head .modal-instruction {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    font-family: Arial;
    color: var(--Black-80, #333);
    width: 100%;
    opacity: 1;
}

.modal .dl-input-group {
    margin-bottom: 20px;
}

.modal .dl-input-group select, .modal .dl-input-group input, .modal .dl-input-group textarea{
    font-size: 18px;
    line-height: 28px;
}

.modal .dl-input-group .input-label{
    font-size: 18px;
    line-height: 28px;   
}

.modal .dls-input-none {
    display: none;
}

.modal .dls-input-group .dls-select-placeholder {
    color: var(--Black-60, #666);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.modal .dls-input-group input:focus+.input-label,
.modal .dls-input-group input.has-content+.input-label {
    top: -5px;
}

.modal .dls-input-group .dls-input-errors {
    color: red;
    position: absolute;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.25s ease-out;
    -o-transition: -o-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
    width: 100%;
    max-width: 830px;
    margin: 30px auto;
    padding: 0 15px;
}

.modal-body {
    width: 100%;
    margin-top: 5px;
    position: relative;
    display: inline-block;
}

.modal-footer {
    text-align: right;
    border-top: 1px solid #e5e5e5;
    display: inline-block;
    width: 100%;
}

.modal-close {
    border: 0px;
}

.modal .dl-check-group {
    margin-bottom: 40px;
}

.dl-checkbox {
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 10px;
}

.dl-checkbox input {
    border: none;
    display: none;
    width: 20px;
    opacity: 0;
    background: 0 0;
}

.dl-checkbox input:checked+label {
    background: var(--brand-color);
    border-color: var(--brand-color);
    color: var(--white);
}

.dl-check-group label {
    display: none;
}

.dls-select-group {
    position: relative;
}

.dl-check-buttons .dl-checkbox label {
    border: 1px solid var(--brand-color);
    position: relative;
    background: var(--white);
    width: auto;
    margin: 0;
    font-weight: normal;
    border-radius: 30px;
    font-size: 19px;
    -webkit-transition: all .2s cubic-bezier(.645, .045, .355, 1);
    transition: all .2s cubic-bezier(.645, .045, .355, 1);
    cursor: pointer;
    display: inline-flex;
    padding: 12px 30px;
}

.dl-check input {
    border: none;
    display: none;
    width: 16px;
    opacity: 0;
    background: 0 0;
}

.dl-check .dl-check-label-dec:hover {
    opacity: 1;
}

.dl-check .dl-check-label-dec {
    display: block;
    cursor: pointer;
    font-size: 19px;
    opacity: .8;
    margin-left: 5px;
}

.dl-check .dl-check-label {
    border: 1px solid #cccccc;
    position: relative;
    width: 28px;
    height: 28px;
    background: var(--white);
    display: inline-block;
    border-radius: 0px;
    -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
}

.dl-check :checked+.dl-check-label {
    background: var(--brand-color);
    border-color: var(--brand-color);
    color: var(--white);
}

.dl-check :checked+.dl-check-label svg {
    opacity: 1;
    filter: alpha(opacity=100);
    fill: var(--white);
}

.dl-check svg {
    opacity: 0.5;
    position: relative;
    top: 1px;
}

.chosen-value,
.value-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
}

input.chosen-value::placeholder {
    width: 100%;
    top: 18px;
    color: var(--black800);
    transition: 0.3s;
    font-size: 19px;
}

.selected-value,
.selected-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
}

.dls-input-group.dls-select-group input {
    font-size: 18px;
}

.chosen-value {
    height: 64.15px;
    padding: 1rem;
    background-color: #fafcfd;
    border: 3px solid transparent;
    transition: 0.3s ease-in-out;
    color: var(--Black-60, #666);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

.chosen-value::-webkit-input-placeholder {
    color: #333;
}

/* .chosen-value:hover {
    cursor: pointer;
} */

.chosen-value:hover::-webkit-input-placeholder {
    color: #333;
}

/* .chosen-value:focus, .chosen-value.open {
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
    outline: 0;
    color: #000;
} */
.chosen-value:focus::-webkit-input-placeholder,
.chosen-value.open::-webkit-input-placeholder {
    color: #000;
}

.selected-list {
    list-style: none;
    margin-top: 4rem;
    /* box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2); */
    overflow: hidden;
    background-color: #fff;
    max-height: 0;
}

.value-list {
    list-style: none;
    margin-top: 4rem;
    background-color: #fff;
    /* box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2); */
    overflow: hidden;
    max-height: 0;
}

.value-list.open {
    max-height: 30rem;

    position: relative;
    overflow: auto;
    position: absolute;
    z-index: 111;
    top: 35px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    padding-top: 30px;
    padding-bottom: 30px;
    transition: 0.3s ease-in-out;
}

.selected-list.open {
    max-height: 30rem;

    position: relative;
    overflow: auto;
    position: absolute;
    z-index: 111;
    top: 35px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    padding-top: 30px;
    padding-bottom: 30px;
    transition: 0.3s ease-in-out;
}

.modal-content .dls-input-group.input-other {
    display: none;
}

.modal-content .dls-input-group.input-other.open {
    display: block;
}


.model input::placeholder {
    color: var(--Black-80, #333);
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
    /* 250% */
}

.chosen-value {
    color: var(--Black-80, #333);
}

.value-list li {
    position: relative;
    height: 5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    opacity: 1;
    color: var(--Black-80, #333);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.selected-list li {
    position: relative;
    height: 5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    opacity: 1;
    color: var(--Black-80, #333);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.value-list li:hover {
    background-color: #f3f3f3;
}

.selected-list li:hover {
    background-color: #f3f3f3;
}

.value-list li.closed {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
}

.selected-list li.closed {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
}

.selected-list.open {
    max-height: 22rem;
}

.caret-none input[type="text"] {
    caret-color: transparent;
}

ul {
    scrollbar-color: #d9d9d9 #ffffff;
    scrollbar-width: thin;
}

ul::-webkit-scrollbar-track {
    background-color: #ffffff;
}

ul::-webkit-scrollbar {
    width: 7px;
    background-color: red;
}

ul::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}

/* Gagan Loader */
/* Loader */
.loader-btn {
    background-color: #000000;
    padding: 20px;
    display: inline-block;
}

button.loader-btn {
    padding: 10px 40px;
}

.loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    background-color: #000000;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left: 4px solid #b6b6b6;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/***new css**/
.modal .dl-input-group .input-label{
    z-index: 0;
}

.modal p.ps-txt {
    color: #666;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 40px;
    opacity: 1;
}

/*****modal media csss*****/
@media screen and (max-width:768px) {

    .modal .modal-content {
        padding: 3rem;
    }

    .modal .modal-head {
        row-gap: 5px;
        column-gap: 3rem;
    }

    .modal .modal-head svg {
        width: 4rem;
    }

    .modal-head .modal-headline {
        font-size: 2.4rem;
        line-height: 3.4rem;
    }

    .modal-head .modal-instruction {
        font-size: 18px;
        line-height: 28px;
    }

    .modal .dls-input-group .dls-select-placeholder {
        font-size: 18px;
        line-height: 28px;
    }

    .modal-body .dls-input-group .input-label {
        font-size: 18px;
    }

    .dl-check-group label {
        display: none;
    }

    .dl-check-buttons .dl-checkbox label {
        font-size: 18px;
    }

    .dl-check .dl-check-label-dec {
        font-size: 18px;
    }

    .select-items div,
    .select-selected option {
        font-size: 18px;
    }

    .select-items div {
        padding: 0 1rem;
    }
}

@media screen and (max-width:767px) {
    .value-list.open {
        max-height: 27rem;
    }

    .selected-list.open {
        max-height: 18rem;
    }
}

@media screen and (max-width:580px) {

    .modal .modal-content {
        padding: 3rem;
    }

    .modal .modal-head {
        row-gap: 5px;
        column-gap: 1rem;
    }

    .modal .modal-head svg {
        width: 3.5rem;
    }

    .modal .modal-body input {
        padding: 16px;
        padding-left: unset;
    }

    .modal-head .modal-headline {
        font-size: 2rem;
        line-height: 2.8rem;
    }

    .modal-head .modal-instruction {
        font-size: 16px;
        line-height: 26px;
    }

    .modal .dls-input-group .dls-select-placeholder {
        font-size: 16px;
        line-height: 26px;
    }

    .modal-body .dls-input-group .input-label {
        font-size: 16px;
        margin: unset;
    }

    .dl-check-group label {
        display: none;
    }

    .dl-check-buttons .dl-checkbox label {
        font-size: 18px;
    }

    .dl-check .dl-check-label-dec {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: unset;
    }

    .select-selected {
        font-size: 16px;
    }

    .select-items div,
    .select-selected option {
        font-size: 16px;
        line-height: 45px;
    }

    .value-list {
        opacity: 0;
    }

    .dls-input-group.dls-select-group input {
        font-size: 16px;
    }

    .selected-list {
        opacity: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .value-list.open {
        max-height: 27rem;
        opacity: 1;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .value-list li {
        height: 45px;
        line-height: unset;
        font-size: 18px;
    }

    .selected-list li {
        height: 45px;
        font-size: 18px;
    }

    .selected-list.open {
        max-height: 18rem;
        opacity: 1;
        padding-top: 20px;
        padding-bottom: 20px;
    }

}