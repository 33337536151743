:root {
  --brand-color: #000000;
  --black100: #111111;
  --black200: #222222;
  --black300: #333333;
  --black400: #444444;
  --black500: #555555;
  --black600: #666666;
  --black700: #777777;
  --black800: #888888;
  --black900: #999999;
  --white: #ffffff;
  --red: #ff0000;
  --background-grey: #f1f1f1;
  --border-color-d: #dddddd;
  --border-color-c: #cccccc;
  --border-color-e: #eeeeee;
}

html {
  scroll-behavior: smooth;
}

* {
  user-select: none;
}

*::selection {
  background: none;
}

*::-moz-selection {
  background: none;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

a:hover {
  cursor: pointer !important;
}

/*common*/
.dl-pos-in {
  position: initial !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-top: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: 62px;
}

.h1-l {
  font-size: 60px;
}

h2,
h3 {
  font-size: 53px;
}

h5 {
  font-size: 26px;
}

h6 {
  font-size: 22px;
}

h4 {
  font-size: 34px;
}

.font-body {
  font-family: Arial, Helvetica, sans-serif;
}

.text-grey {
  color: var(--black600);
}

.font-s {
  font-size: 16px;
}

.font-m {
  font-size: 18px;
}

.font-62 {
  font-size: 62px;
}

.font-n {
  font-size: 20px;
}

.font-p {
  font-weight: normal;
  opacity: .8;
}

.font-l {
  font-size: 22px;
}

.font-xl {
  font-size: 32px;
}

.block {
  display: block;
}

.dl-wrapper {
  width: 100%;
  float: left;
}

.line-break {
  display: block;
}

.m0 {
  margin: 0 !important;
}

.mT0 {
  margin-top: 0 !important;
}

.mT10 {
  margin-top: 10px;
}

.mT30 {
  margin-top: 30px;
}

.mR10 {
  margin-right: 10px;
}

.mR20 {
  margin-right: 20px;
}

.mT20 {
  margin-top: 20px;
}

.mB20 {
  margin-bottom: 20px;
}

.mB30 {
  margin-bottom: 30px;
}

.mB50 {
  margin-bottom: 50px;
}

.mB70 {
  margin-bottom: 70px;
}

.mB60 {
  margin-bottom: 60px;
}

.mB10 {
  margin-bottom: 10px;
}

.mB15 {
  margin-bottom: 15px;
}

.mB25{
  margin-bottom: 25px;
}

.mB40 {
  margin-bottom: 40px;
}

.mL15 {
  margin-left: 15px;
}

.mL10 {
  margin-left: 10px;
}

.mL5 {
  margin-left: 5px;
}

.mT30 {
  margin-top: 30px;
}

.mT40 {
  margin-top: 40px;
}

.mT50 {
  margin-top: 50px;
}

.mT100 {
  margin-top: 100px;
}

.pB0 {
  padding-bottom: 0 !important;
}

.pT0 {
  padding-top: 0 !important;
}

.pR30 {
  padding-right: 30px;
}

.p30 {
  padding: 30px
}

.p40 {
  padding: 40px
}

.p60 {
  padding: 60px
}

.p0 {
  padding: 0px !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50px;
}

.w-60 {
  width: 60px;
}

.w-200 {
  width: 200px !important;
}

.w-70 {
  width: 70px;
}

.font-w-600 {
  font-weight: 600;
}

.font-42 {
  font-size: 42px;
}

.transition {
  transition: all .15s;
  -webkit-transition: all .15s;
  -ms-transition: all .15s;
  -moz-transition: all .15s;
}

.hover-scale-image {
  position: relative;
  overflow: hidden;
}

.hover-scale-image img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  will-change: transform;
  transition: transform 1s cubic-bezier(.215, .61, .355, 1);
  -ms-transition: transform 1s cubic-bezier(.215, .61, .355, 1);
  -webkit-transition: transform 1s cubic-bezier(.215, .61, .355, 1);
  -ms-transition: transform 1s cubic-bezier(.215, .61, .355, 1);
}

.hover-scale:hover .hover-scale-image img {
  transform: scale(1.05) translateZ(0);
  -moz-transform: scale(1.05) translateZ(0);
  -webkit-transform: scale(1.05) translateZ(0);
  -ms-transform: scale(1.05) translateZ(0);
}

.border-b-grey {
  border-bottom: 1px solid var(--black200);
}

.border-bottom-grey {
  border-bottom: 1px solid var(--border-color-d);
}

.anchor-none {
  color: inherit;
  text-decoration: none;
}

.anchor-none:hover,
.anchor-none:active,
.anchor-none:focus {
  text-decoration: none;
}

.dl-hover {
  background: var(--black100);
  box-shadow: inset 0 0 0 0 var(--black200);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.dl-hover:hover {
  box-shadow: inset 400px 50px 0 0 var(--black100);
}

button {
  background-color: transparent;
}

.dl-button.primary svg {
  fill: var(--white);
}

.dl-button.disabled {
  pointer-events: none;
}

.dl-button.light {
  font-weight: normal;
}

.dl-button.primary {
  background: var(--brand-color);
  color: var(--white);
  box-shadow: inset 0 0 0 0 var(--black300);
}

.dl-button.primary:hover {
  box-shadow: inset 400px 50px 0 0 var(--brand-color);
}

.dl-button {
  color: var(--brand-color);
  border: 1px solid var(--brand-color);
  padding: 20px 40px;
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 var(--brand-color);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  display: inline-block;
}

.dl-button-social-icon {
  gap: 1.2rem;
  display: flex;
  width: fit-content;
  align-items: center;
}

._event_announcement_buttons {
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 992px) {
  ._event_announcement_buttons .dl-button {
    padding: 16px 27px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  ._event_announcement .dl-row {
    margin: 0;
  }

  ._event_announcement h2 {
    font-size: 32px;
  }

  ._event_announcement_buttons {
    display: grid;
    margin-top: 20px;
  }
}

@media screen and (max-width: 320px) {
  ._event_announcement_buttons a.dl-button {
    font-size: 14px;
  }
}

.dl-breadcrumb {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 15px;
  position: absolute;
  bottom: 30px;
}

.dl-breadcrumb li:last-child {
  opacity: 1;
}

.dl-breadcrumb li:not(:last-child):hover {
  opacity: 1;
}

.visible-dlxss {
  display: none !important;
}

.overflow-hide {
  overflow: hidden;
}

.overflow-hide-important {
  overflow: hidden !important;
}

.dl-breadcrumb .arrow {}

.dl-breadcrumb li {
  display: inline;
  font-size: 16px;
  opacity: .7;
}

.dl-breadcrumb li a {
  color: var(--white);
  text-decoration: none;
}

.dl-breadcrumb li:last-child {
  opacity: 0.4;
}

.dl-breadcrumb li+li:before {
  padding: 8px;
  color: var(--white);
  content: "/\00a0";
}

.dl-pills li {
  margin-right: 10px;
}

.dl-pills li.active .dl-button {
  font-weight: 600;
  background: var(--brand-color);
  color: var(--white);
}

.dl-button.round {
  border-radius: 100px;
}

.dl-button.small {
  font-size: 18px;
  text-transform: none;
  padding: 10px 25px;
}

.dl-button.medium {
  font-size: 19px;
  text-transform: none;
  padding: 17px 28px;
  font-weight: normal;
}

.dl-button.black {
  background: var(--brand-color);
  box-shadow: inset 0 0 0 0 var(--black300);
  color: var(--white);
}

.dl-button.black:hover {
  box-shadow: inset 400px 50px 0 0 var(--brand-color);
}

.dl-button.white {
  color: var(--white);
  border: 1px solid var(--white);
}

.dl-button:hover,
.dl-button:active,
.dl-button:focus {
  text-decoration: none;
  color: inherit;
  box-shadow: inset 400px 50px 0 0 var(--brand-color);
  color: var(--white);
}

.dl-button.white:hover,
.dl-button.white:active,
.dl-button.white:focus {
  text-decoration: none;
  color: inherit;
  box-shadow: inset 400px 50px 0 0 var(--white);
  color: var(--brand-color);
}

.dl-button:hover svg,
.dl-button:active svg {
  fill: var(--white);
}

.dl-button.white:hover svg,
.dl-button.white:active svg {
  fill: var(--brand-color);
}

.dl-button i {
  padding-left: 12px;
}

.dl-button.white svg {
  fill: var(--white);
}

.dl-button svg {
  fill: var(--brand-color);
  width: 24px;
  position: relative;
  top: 2px;
}

.dl-anchor.white {
  border-bottom: 1px solid rgba(255, 255, 255, .8);
}

.dl-anchor {
  position: relative;
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, .8);
}

.dl-anchor:hover {
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.dl-anchor.white:hover {
  opacity: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.dl-block {
  padding: 140px 0;
  width: 100%;
  float: left;
}

.dl-block-sm {
  padding: 70px 0;
}

p {
  font-size: 20px;
  opacity: .8;
}

.dl-social li a svg {
  fill: var(--brand-color);
}

.dl-social.white li a svg {
  fill: var(--white);
}

.dl-social li:first-child {
  margin-left: 0;
}

.dl-social li {
  margin-left: 10px;
}

.dl-social {
  list-style: none;
  padding: 0;
}

.dl-block-dark .dl-social li:hover {
  opacity: 1;
}

.dl-block-dark .dl-social li {
  opacity: .7;
}

.nav-up {
  top: -96px;
}

._brand_design_partner .nav-up {
  top: -110px;
}

.ul-none {
  list-style: none;
  padding: 0;
}

.dl-list li {
  margin-bottom: 8px;
  opacity: .8;
}

.dl-list {
  padding-left: 30px;
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grid {
  flex: 1 1 33.333333%;
}

.flex-dc {
  flex-direction: column;
}

.flex-dr {
  flex-direction: row;
}

.flex-jc {
  justify-content: space-between;
}

.flex-jcc {
  justify-content: center;
}

.flex-ac {
  align-items: center;
}

.grey-bg {
  background: var(--black100);
}

.dl-block-dark h2,
.dl-block-dark h6,
.dl-block-dark h5,
.dl-block-dark h3,
.dl-block-dark h4 {
  font-weight: 600;
}

.dl-block-dark .list li,
.dl-block-dark p {
  opacity: .7;
}

.dl-block-dark {
  background: var(--brand-color);
  color: var(--white);
}

.v-resize {
  resize: vertical;
}

.border-top {
  border-top: 1px solid var(--black200);
}

.border-bottom {
  border-bottom: 1px solid var(--black200);
}

.focus-hover {
  border-bottom: 1px solid var(--border-color-c);
  position: relative;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  position: relative;
}

.dl-block-dark .focus-hover {
  border-color: var(--black200);
}

.focus-hover .focus-border {
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--brand-color);
  transition: 0.4s;
}

.dl-block-dark .focus-hover .focus-border {
  background-color: var(--white);
}


.focus-hover:hover .focus-border {
  width: 100%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  left: 0;
}

.dl-pointer {
  cursor: pointer;
}

/*header*/
header {
  padding: 10px 0;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
  background: var(--white);
  z-index: 11;
}

header .dl-container-fluid {
  max-width: 1400px;
  margin: auto;
}

header .logo {
  width: 240px;
}

header .dl-logo {
  width: 240px;
  transition: width .5s ease-in-out;
  -webkit-transition: width .5s;
  -ms-transition: width .5s;
  -moz-transition: width .5s;
  overflow: hidden;
}

header .hidden-mobile ul._nav_items {
  gap: 3rem;
}

._brand_design_partner header .logo {
  width: 351px;
  height: auto;
}

._brand_design_partner header .dl-logo {
  width: 351px;
  transition: width .5s ease-in-out;
  -webkit-transition: width .5s;
  -ms-transition: width .5s;
  -moz-transition: width .5s;
  overflow: hidden;
}

header.scrolled .dl-logo {
  width: 78px;
}

header nav ul {
  list-style: none;
  float: right;
  margin: 0;
  padding: 0;
}

header nav ul li {
  font-weight: bold;
  font-size: 18px;
}

header nav ul li:first-child {
  margin-left: 0;
}

header nav ul li .dl-button {
  margin-left: 20px;
  display: flex;
  white-space: pre;
}

header nav ul li a {
  color: var(--brand-color);
  padding: 15px 24px;
}

header nav ul li a:hover {
  text-decoration: none;
}

.homeImage {
  position: absolute;
}

.homeImage video {
  width: 100%;
  height: 100%;
}

/*home*/
.dl-roar {
  position: relative;
  width: 100%;
  float: left;
  padding-top: 120px;
  /* background: url("../img/roar-bg.svg"); */
  background-repeat: no-repeat;
  background-position: right bottom -5px;
  margin-top: 92px;
  background: url('../img/roar.mp4') no-repeat center center fixed;
  background-size: 800px 500px;

}

.dl-roar .dl-social {
  position: absolute;
  bottom: 20px;
}

.dl-block .dl-subHead {
  font-size: 18px;
  text-transform: uppercase;
}

hr {
  border: 1px solid var(--black200);
  width: 100%;
  margin: 120px 0 120px;
  float: left;
}

.dl-services .dl-process li {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
}

.dl-block .dl-icon.w-80 img {
  width: 80px;
}

.dl-block .card-title {
  opacity: 1;
  font-size: 24px;
  font-weight: 600;
}

/*card list*/
.dl-card-list {
  padding-top: 40px;
}

.dl-card-list .title {
  margin-bottom: 10px;
  margin-top: 30px;
  line-height: 36px;
}

.dl-card-list ul {
  padding-left: 0;
  margin-top: 20px;
}

.dl-card-list ul li {
  font-size: 19px;
  opacity: .7;
  margin-bottom: 10px;
  display: block;
}

/*case studies*/
.dl-cs-card {
  width: 100%;
  display: block;
}

.dl-cs-card .dl-csImg img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  will-change: transform;
  transition: transform 1s cubic-bezier(.215, .61, .355, 1);
  -ms-transition: transform 1s cubic-bezier(.215, .61, .355, 1);
  -webkit-transition: transform 1s cubic-bezier(.215, .61, .355, 1);
  -ms-transition: transform 1s cubic-bezier(.215, .61, .355, 1);
}

.dl-cs-card .dl-csImg:hover img {
  transform: scale(1.05) translateZ(0);
  -moz-transform: scale(1.05) translateZ(0);
  -webkit-transform: scale(1.05) translateZ(0);
  -ms-transform: scale(1.05) translateZ(0);
}

.dl-cs-card .dl-csImg {
  position: relative;
  overflow: hidden;
}

.dl-cs-card .dl-csWrap {
  margin-bottom: 80px;
  margin-top: 20px;
  display: block;
  color: inherit;
}

/*Clients*/
.dl-clients ul {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
}

.dl-clients ul li img {
  height: 60px;
}

.dl-clients ul li {
  list-style: none;
  display: inline-flex;
  margin: 0 40px;
}

.dl-clients .slick-slide img {
  margin: auto;
}


.dl-clients_grid .dl-clients-slider {
  margin-top: 3rem;
}

.dl-clients_grid .dl-clients-slider img {
  margin: 0;
  width: auto;
  height: 90px;
}

.dl-clients_grid .dl-clients-slider .dl-clients-items {
  display: grid;
  grid-template-areas:
    "clients-item-1 clients-item-1 clients-item-2 clients-item-2 clients-item-3 clients-item-3 "
    "clients-item-1 clients-item-1 clients-item-2 clients-item-2 clients-item-3 clients-item-3"
    "blank  clients-item-4 clients-item-4 clients-item-5 clients-item-5 blank02"
    "blank  clients-item-4 clients-item-4 clients-item-5 clients-item-5 blank02";
  width: fit-content;
  column-gap: 5rem;
  row-gap: 6rem;
  margin: 6rem auto 0;
}

.dl-clients_grid .dl-clients-slider .dl-clients-item:nth-child(1) {
  grid-area: clients-item-1;
}

.dl-clients_grid .dl-clients-slider .dl-clients-item:nth-child(2) {
  grid-area: clients-item-2;
}

.dl-clients_grid .dl-clients-slider .dl-clients-item:nth-child(3) {
  grid-area: clients-item-3;
}

.dl-clients_grid .dl-clients-slider .dl-clients-item:nth-child(4) {
  grid-area: clients-item-4;
}

.dl-clients_grid .dl-clients-slider .dl-clients-item:nth-child(5) {
  grid-area: clients-item-5;
}

@media screen and (max-width:992px) {
  .dl-clients_grid .dl-clients-slider .dl-clients-items {
    column-gap: 2.5rem;
  }

  .dl-clients_grid .dl-clients-slider img {
    width: auto;
    height: 78.4px;
  }
}

@media screen and (max-width:767px) {
  .dl-clients_grid .dl-clients-slider .dl-clients-items {
    grid-template-areas:
      "clients-item-1 clients-item-1 clients-item-2 clients-item-2 "
      "clients-item-3 clients-item-3 clients-item-4 clients-item-4"
      "blank  clients-item-5 clients-item-5 blank02";
    column-gap: 5rem;
    row-gap: 4rem;
    margin: 4rem auto 0;
  }

  #models.dl-scroll-block h3 {
    padding-top: 70px;
  }
}

@media screen and (max-width: 599px) {
  .dl-clients_grid .dl-clients-slider .dl-clients-items {
    grid-template-areas: unset;
    row-gap: 0;
  }

  .dl-clients_grid.dl-clients .dl-clients-slider .dl-clients-item {
    grid-area: unset;
    margin-top: 4rem;
  }

  .dl-clients_grid.dl-clients .dl-clients-slider .dl-clients-item:nth-child(1) {
    margin-top: 0;
  }
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;

  list-style-type: none;
}

.slick-dots li {
  margin: 15px 8px 0;
}

.slick-dots li button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #222;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #555;
}

.dl-clients-slider img {
  margin: 40px 0 0;
}

/*testimonials*/
.testimonial blockquote {
  border: none;
}

.testimonial .content {
  font-family: Poppins, sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 50px;
  color: var(--brand-color);
  opacity: 1;
}

.testimonial .dl-icon {
  margin-bottom: 0px;
  margin-left: -80px;
}

.testimonial li img {
  max-width: 120px;
  height: 120px;
  width: 100%;
  border-radius: 120px;
}

.testimonial ul h6 {
  font-size: 40px;
  color: var(--brand-color);
  margin: 15px 0px 0px;
}

.testimonial ul p {
  font-size: 24px;
}

/*FAQs*/
.dl-accordian ul {
  list-style: none;
  padding: 0;
}

.dl-accordian ul p {
  color: var(--brand-color);
  opacity: .7;
  font-size: 19px;
  line-height: 24px;
  display: none;
  margin-top: 20px;
}

.dl-accordian ul svg {
  position: absolute;
  right: 0;
  top: 45px;
  height: 25px;
  width: 25px;
}

.dl-block-dark .dl-accordian ul li svg {
  fill: #fff;
}

.dl-block-dark .dl-accordian ul li * {
  color: #fff;
}

.dl-block-dark .dl-accordian ul li {
  border-top: unset;
  border-bottom: 1px solid rgba(214, 214, 214, 0.2);
}

.dl-accordian ul li.active svg {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  top: 45px;
  transition: all 0.3s;
}

.dl-accordian ul li.active h5 {
  opacity: 1;
}

.dl-accordian ul li.active p {
  display: block;
}

.dl-accordian ul li {
  padding: 20px 0;
  padding-right: 40px;
  position: relative;
  border-top: 1px solid var(--border-color-d);
  padding: 36px 0;
}

.dl-accordian ul h5 {
  font-size: 29px;
  position: relative;
  margin-top: 0;
  opacity: 1;
  margin-bottom: 0;
  font-weight: 500;
  padding-right: 20px;
  line-height: normal;
}

.dl-accordian ul li:hover h5 {
  opacity: 1;
}

/*footer*/
footer.dl-block {
  padding-bottom: 100px;
}

footer.dl-block .title {
  font-size: 22px;
  opacity: 1;
  color: var(--white);
}

footer .dl-foot-contact img {
  width: 100px;
}

footer .dl-card-list ul li:hover {
  opacity: 1;
}

footer nav ul a {
  color: var(--white);
  font-size: 19px;
  opacity: 1;
}

footer .dl-card-list ul li {
  margin-bottom: 14px;
}

footer .dl-card-list ul li b {
  opacity: 1;
}

footer nav ul b img {
  width: 20px;
}

footer ._footer_column {
  gap: 2.5rem;
  display: grid;
}

footer nav ul a:hover,
footer nav ul a:focus,
footer nav ul a:active {
  text-decoration: none;
  opacity: 1;
}

._flag_wrapper {
  max-width: 3rem;
  height: 2rem;
  display: flex;
  align-items: center;
  width: fit-content;
}

._flag_media {
  width: 100%;
  height: 1.8rem;
  object-fit: contain;
}

._dl_location_info {
  gap: 1rem;
  display: flex;
  position: relative;
  align-items: center;
}

._dl_location_text {
  width: 100%;
}

._roll-on_wrapper {
  position: relative;
  height: 75px;
}

/* ._roll-on_wrapper div {
    height: 26.5667px;
    overflow: hidden;
    position: relative;
  } */

._dl_location_info ._roll-on {
  opacity: 0;
  top: 20px;
  position: absolute;
}

._roll-on:nth-child(1) {
  animation: rollDown 11s forwards infinite;
}

._roll-on:nth-child(2) {
  animation: rollDown2 11s forwards infinite;
}

._roll-on:nth-child(3) {
  animation: rollDown3 11s forwards infinite;
}

p._roll-on {
  margin: 0;
  line-height: 20px;
}

._delayed-roll-on {
  opacity: 0;
  top: 20px;
  position: relative;
}

._delayed-roll-on._delayed-text_01 {
  animation: rollDown 11s forwards infinite;
  animation-delay: 1.1s;
}

._delayed-roll-on._delayed-text_02 {
  animation: rollDown2 11s forwards infinite;
  animation-delay: 1.1s;
}

._delayed-roll-on._delayed-text_03 {
  animation: rollDown3 11s forwards infinite;
  animation-delay: 1.1s;
}

@media screen and (max-width: 992px) {
  ._roll-on_wrapper ._dl_location_info {
    display: flex;
    justify-content: center;
    overflow: visible;
  }

  ._dl_location_text {
    width: 11.4rem;
    max-width: 11.4rem;
  }

  ._roll-on {
    display: flex;
    text-align: left;
  }

  ._delayed-roll-on {
    padding-left: 4px;
  }
}


/*mobile menu*/
.hamburger:focus,
.hamburger:visited,
.hamburger:active {
  outline: none;
}

.hamburger {
  position: fixed;
  z-index: 12;
  top: 14px;
  right: 15px;
  cursor: pointer;
  transition: all .15s;
}

.dl-mobile-nav ul li .email {
  margin-top: 30px;
}

.dl-mobile-nav .links a {
  font-family: 'Poppins', sans-serif;
  font-size: 34px;
  font-weight: 600;
  color: var(--white);
  display: block;
}

.dl-mobile-nav .dl-social li a svg {
  width: 34px;
  height: 34px;
}

.dl-mobile-nav {
  -webkit-transition: .4s;
  transition: .4s;
  position: relative;
  z-index: 11;
  display: none;
}

.dl-mobile-nav.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.dl-mobile-nav.active .mB30 {
  -ms-animation: slide-up2 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  -webkit-animation: slide-up2 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up2 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation-delay: .1s;
}

.dl-mobile-nav.active ul {
  -ms-animation: slide-up 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  -webkit-animation: slide-up 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation-delay: .2s;
}

.dl-mobile-nav.active .font-s {
  -ms-animation: slide-up2 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  -webkit-animation: slide-up2 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up2 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation-delay: .3s;
}

.dl-mobile-nav.active .links a {
  -ms-animation: slide-up 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  -webkit-animation: slide-up 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@keyframes slide-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up2 {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: .7;
  }
}

/*about*/
.dl-about ul li {
  font-size: 19px;
  line-height: 36px;
}

.dl-page-banner {
  padding-top: 220px;
}

.dl-mission .icons img:nth-of-type(1) {
  left: -8px;
  top: -1px;
}

.dl-mission img {
  height: 90px;
  margin-top: 120px;
  margin-bottom: 40px;
}

.dl-mission .icons {
  margin-top: 120px;
  margin-bottom: 40px;
  height: 90px;
  width: 100%;
  float: left;
  text-align: center;
}

.dl-about-slider .slick-cloned.slick-active img {
  display: inline;
}

.dl-about-slider .slick-slide img {
  display: inline;
}

.dl-initiatives ul li {
  margin-right: 40px;
}

@media screen and (max-width:991px) {
  ._about.dl-initiatives ul li {
    margin-right: 20px;
  }

  #models.dl-scroll-block h3 {
    padding-top: 100px;
  }
}

.dl-initiatives ul li img {
  height: 80px;
}

.dl-about-slider #dl-about-type {
  display: block !important;
}

.dl-block-white .swiper-button-next,
.dl-block-white .swiper-button-prev {
  color: var(--brand-color) !important;
}

.dl-team-card .dl-h5-title svg {
  position: relative
}

.dl-team-inspire-card .image {
  flex: 0 0 370px;
}

.dl-team-inspire-card img {
  width: 100%;
}

.dl-icon-card {
  padding: 30px 0px;
}

.flex-grid.dl-icon-card {
  padding: 30px 10px;
}

.dl-icon-card h4 {
  font-size: 26px;
}

.dl-h5-title,
.dl-h4-title {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: var(--white);
}

.dl-h5-title {
  font-size: 24px;
}

._our-team .dl-team-card p {
  font-size: 19px;
}

@media screen and (max-width:600px) {
  ._our-team .dl-team-card .dl-h5-title {
    font-size: 22px;
  }

  ._our-team .dl-team-card p {
    font-size: 18px;
  }
}

.dl-block-cta {
  background: var(--black200);
  padding: 0;
}

.dl-block-cta a {
  padding: 100px;
  width: 100%;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.dl-block-cta a:avtive {
  box-shadow: inset 0 0 0 0 var(--brand-color);
}

.dl-block-cta .left {
  background: var(--black100);
  box-shadow: inset 0 0 0 0 var(--brand-color);
}

.dl-block-cta .left:hover {
  box-shadow: inset 400px 50px 0 0 var(--black100);
}

.dl-block-cta .right {
  background: var(--black200);
  box-shadow: inset 0 0 0 0 var(--brand-color);
}

.dl-block-cta .right:hover {
  box-shadow: inset 400px 50px 0 0 var(--black200);
}

.dl-team-inspire-card h3 {
  font-size: 38px;
}

/*contact*/
.dl-input-group {
  margin-bottom: 30px;
  position: relative;
}

.dl-input-group .input-label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 18px;
  color: var(--black800);
  transition: 0.3s;
  font-size: 19px;
  z-index: -1;
}

.dl-input-group select+.input-label,
.dl-input-group textarea:focus+.input-label,
.dl-input-group input:focus+.input-label,
.dl-input-group input.has-content+.input-label,
.dl-input-group textarea.has-content+.input-label {
  top: -8px;
  font-size: 16px;
  color: var(--black600);
  transition: 0.3s;
}

.dl-input-group select {
  -webkit-appearance: none;
}

.dl-select-group i {
  position: absolute;
  right: 0;
  top: 18px;
}

.dl-input-group select,
.dl-input-group input,
.dl-input-group textarea {
  background: transparent;
  border: 0px;
  border-bottom: 1px solid var(--black300);
  font-size: 19px;
  padding: 18px;
  padding-left: 0px;
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  position: relative;
}

.dl-input-group textarea~.focus-border {
  bottom: 5px;
}

.dl-input-group .focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--brand-color);
  transition: 0.4s;
}

.dl-input-group .dl-input-errors {
  position: absolute;
  color: red;
  bottom: -25px;
}

.dl-input-group select.error~.focus-border,
.dl-input-group input.error~.focus-border,
.dl-input-group textarea.error~.focus-border {
  background-color: var(--red);
  width: 100%;
  left: 0;
}

.dl-input-group .dl-button {
  width: auto;
  padding: 20px 40px;
}

.dl-input-group textarea+.focus-border {
  bottom: 5px;
}

.dl-input-group input:focus~.focus-border,
.dl-input-group select:focus~.focus-border,
.dl-input-group textarea:focus~.focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

.dl-check .dl-check-label-dec a {
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}

.dl-checkbox {
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 10px;
}

.dl-checkbox input {
  border: none;
  display: none;
  width: 20px;
  opacity: 0;
  background: 0 0;
}

.dl-checkbox input:checked+label {
  background: var(--brand-color);
  border-color: var(--brand-color);
  color: var(--white);
}

.dl-check-group label {
  display: none;
}

.dl-check-buttons .dl-checkbox label {
  border: 1px solid var(--brand-color);
  position: relative;
  background: var(--white);
  width: auto;
  margin: 0;
  font-weight: normal;
  border-radius: 30px;
  font-size: 19px;
  -webkit-transition: all .2s cubic-bezier(.645, .045, .355, 1);
  transition: all .2s cubic-bezier(.645, .045, .355, 1);
  cursor: pointer;
  display: inline-flex;
  padding: 12px 30px;
}

.dl-check input {
  border: none;
  display: none;
  width: 16px;
  opacity: 0;
  background: 0 0;
}

.dl-check .dl-check-label-dec:hover {
  opacity: 1;
}

.dl-check .dl-check-label-dec {
  display: block;
  cursor: pointer;
  font-size: 19px;
  opacity: .8;
  margin-left: 5px;
}

.dl-check .dl-check-label {
  border: 1px solid #cccccc;
  position: relative;
  width: 32px;
  height: 32px;
  background: var(--white);
  display: inline-block;
  border-radius: 0px;
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
}

.dl-check :checked+.dl-check-label {
  background: var(--brand-color);
  border-color: var(--brand-color);
  color: var(--white);
}

.dl-check :checked+.dl-check-label svg {
  opacity: 1;
  filter: alpha(opacity=100);
  fill: var(--white);
}

.dl-check svg {
  opacity: 0.5;
  position: relative;
  top: 3px;
}

/***upload document**/
.upload-area {
  text-align: center;
  border: 1px dashed var(--Black-60, #666);
  padding: 15px 10px;
  position: relative;
  cursor: pointer;
}


.upload-area input,
.upload-area input[type=file]::-webkit-file-upload-button {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 10;
  opacity: 0;
}

.selected-file p {
  color: var(--Black, var(--Base-Black, #000));
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
  margin: 0;
}

.selected-file {
  display: flex;
  align-items: center;
  gap: 50px;
  padding-top: 20px;
}

#file-name-display .delete-file {
  font-size: 30px;
  line-height: 1px;
  cursor: pointer;
}

.upload-inner p,
.upload-inner p a {
  margin-bottom: 0;
  color: var(--Black-60, #666);
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
}

.upload-inner p a {
  font-weight: 700;
  cursor: pointer;
  position: relative;
  z-index: 11;
}

.upload-inner {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
}

.upload-inner img {
  width: 20px;
}

#file-size-display {
  font-size: 14px;
  color: red;
  opacity: 1;
  margin: 0;
}

/*Careers*/
.dl-job-card {
  padding: 50px 0;
}

.dl-job-card:hover {
  padding-left: 20px;
}

.dl-job-card i {
  width: 90px;
}

.dl-job-summary-card {
  background: var(--background-grey);
}

.dl-job-banner {
  position: relative;
  overflow: hidden;
}

.dl-job-banner:after {
  opacity: .1;
  position: absolute;
  height: 320px;
  width: 320px;
  top: 140px;
  right: 125px;
  content: "";
  background-repeat: no-repeat;
}

.dl-job-banner.aces:after {
  background-image: url(../img/icons/aces.svg);
}

.dl-job-banner.alphas:after {
  background-image: url(../img/icons/alphas.svg);
}

.dl-job-banner.blacks:after {
  background-image: url(../img/icons/blacks.svg);
}

/* .dl-job-banner.archers:after {
    background-image: url(../img/icons/archers.svg);
  }
   */
.dl-job-banner.rogues:after {
  background-image: url(../img/icons/rogues.svg);
}

/*Slider*/
.mySwiper .swiper-pagination {
  display: none;
}

.mySwiper .swiper-pagination-bullet-active {
  background: var(--brand-color);
}

.swiper.mySwiper {
  padding-top: 30px;
}

.mySwiper .swiper-slide {
  transition: transform 0.30s ease-in-out;
}

.imgSwiper-btns {
  position: absolute;
  top: 0.8rem;
  gap: 4.3rem;
  display: flex;
  right: 1rem;
}

.imgSwiper-btns .swiper-button-next,
.imgSwiper-btns .swiper-button-prev {
  position: static;
  margin: 0;
}

.mySwiper .swiper-slide.swiper-slide-prev {
  transform: scale(0.8);
}

.blogSwiper .swiper-slide.swiper-slide-prev {
  transform: scale(1);
}

.mySwiper .swiper-slide.swiper-slide-next {
  transform: scale(0.8);
}

.blogSwiper .swiper-slide.swiper-slide-next {
  transform: scale(1);
}

.swiper-button-next,
.swiper-button-prev {
  top: 30px !important;
  left: auto !important;
  color: var(--white) !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  right: 80px !important;
}

.dl-blog {
  overflow: hidden;
}

.dl-blog .dl-row {
  position: relative;
}

.blogSwiper {
  overflow: hidden !important;
  position: static !important;
}


.blogSwiper.swiper .swiper-button-next,
.blogSwiper.swiper .swiper-button-prev {
  top: 50% !important;
  left: auto !important;
  color: rgb(0, 0, 0) !important;
}

.blogSwiper.swiper .swiper-button-next,
.blogSwiper.swiper .swiper-rtl .swiper-button-prev {
  right: -5%;
}

.blogSwiper.swiper .swiper-button-prev,
.blogSwiper.swiper .swiper-rtl .swiper-button-next {
  right: unset !important;
  left: -5% !important;
}

@media screen and (max-width:1199px) {

  .blogSwiper.swiper .swiper-button-prev,
  .blogSwiper.swiper .swiper-rtl .swiper-button-next {
    right: unset !important;
    left: -3% !important;
  }

  .blogSwiper.swiper .swiper-button-next,
  .blogSwiper.swiper .swiper-rtl .swiper-button-prev {
    right: -3%;
  }
}

@media screen and (max-width:991px) {

  .blogSwiper .swiper-slide.swiper-slide {
    transform: scale(1);
  }

  .blogSwiper .swiper-slide.swiper-slide {
    transform: scale(1);
  }
}

@media (max-width: 599px) {
  .blogSwiper.swiper {
    height: fit-content;
  }

  .blogSwiper .swiper-slide.swiper-slide-active {
    margin-left: unset;
  }
}

.dl-page-title {
  font-size: 20px;
  font-weight: normal;
  opacity: .5;
  text-transform: uppercase;
}

/*Error 404*/
.dl-error h2 {
  font-size: 116px;
}


/* element {
    transform: scale(0.6);
  
    right: -25%;
  
  }
  .homeImage {
    position: absolute;
  
    top: -33.5%;
  
    border: 0;
  
  } */
.dl-roar {
  max-width: 100vw;
  overflow: hidden;
}

.homeImage {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  left: 50%;
  transform: translateX(-50%);
}

.homeImage video {

  right: 0;
  width: 1438.5px;
  height: auto;
  bottom: 119px;
  clip-path: inset(1px 1px);
  border: 0;
  position: absolute;

}

@media screen and (max-width: 1440px) {
  .homeImage video {
    width: 1300px;
  }
}

@media screen and (max-width: 1366px) {
  .homeImage video {
    width: 1000px;
  }
}

@media screen and (max-width: 1200px) {
  .homeImage video {
    width: 880px;
    /* width: 82%; */
  }
}

@media screen and (max-width: 991px) {
  .homeImage video {
    bottom: 98px;
    width: 50vw;
  }
}

@media screen and (max-width: 767px) {
  .homeImage {
    z-index: -1;
  }

  .homeImage video {
    bottom: 67px;
    width: 50vw;
  }
}

.dl-error {
  position: relative;
  width: 100%;
  float: left;
  padding-top: 90px;
  background: url(../img/roar-bg.svg);
  background-repeat: no-repeat;
  margin-top: 102px;
  background-position: right 2px;
}

/*services*/
.dl-border-card {
  padding: 40px 0;
}

.dl-card-stat:hover,
.dl-card-service:hover {
  opacity: 1;
}

.dl-card-stat,
.dl-card-service {
  padding: 28px 0;
  opacity: .8;
}

.sticky-sub-nav {
  width: 100%;
  float: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 1;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -moz-transition: all 0.25s;
}

.sticky-sub-nav.sub-nav-down {
  top: 92px;
  z-index: 10;
  border-top: 1px solid var(--border-color-e);
}

.sticky-sub-nav li.active {
  opacity: 1;
}

.sticky-sub-nav li:hover {
  opacity: 1;
}

.sticky-sub-nav li a.active:after {
  content: "";
  left: 0;
  bottom: -1px;
  right: 0;
  height: 2px;
  position: absolute;
  background: var(--brand-color);

}

.sticky-sub-nav li a {
  opacity: .7;
  position: relative;
}

.sticky-sub-nav li a.active {
  opacity: 1;
}

.sticky-sub-nav li a {
  cursor: pointer;
  padding: 20px 35px;
  transition: all 1.5s;
  display: block;
}

/*animations*/
.amin-path {
  stroke-width: 4;
  stroke: var(--white);
  stroke-dasharray: 1043;
  stroke-dashoffset: 0;
}

.dark .amin-path {
  stroke: var(--brand-color);
}

.icon-animate:hover .amin-icon .amin-path,
.dl-card-stat:hover .amin-icon .amin-path,
.dl-service-card:hover .amin-icon .amin-path,
.dl-icon-card:hover .amin-icon .amin-path,
.dl-card-list:hover .amin-icon .amin-path {
  animation: type-icon 2.8s ease forwards;
}

@keyframes type-icon {
  0% {
    stroke-dashoffset: 1043;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

/* Loader */
.loader-btn {
  background-color: #000000;
  padding: 20px;
  display: inline-block;
}

button.loader-btn {
  padding: 10px 40px;
}

.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  background-color: #000000;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left: 4px solid #b6b6b6;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}




/*======================================= for dropdown menu ============================================*/
.dl_drop-menu {
  margin-right: 15px;
  position: relative;
}

.dl_drop-menu .dl_drop-menu_btn {
  gap: 8px;
  height: 60px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.dl_drop-menu .dl_drop-menu_btn_text {
  padding: 0;
}

.dl_drop-menu .dl_drop-menu_btn_icon {
  display: flex;
  width: fit-content;
  height: fit-content;
}

.dl_drop-menu .dl_drop-menu_btn_icon img {
  width: 22.54px;
  height: 21.68px;
  object-fit: contain;
}

.dl_drop-menu .dl_drop-menu_btn_chevron-down_icon svg {
  width: 19px;
  height: 10px;
  transition: transform 0.3s ease-in-out;
}


.dl_drop-menu .dl_drop-menu_options {
  top: 60px;
  opacity: 0;
  visibility: hidden;
  width: 420px;
  display: flex;
  padding: 25px;
  position: absolute;
  background: #FFF;
  flex-direction: column;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.10);
}

.dl_drop-menu_options[data-position="left"] {
  left: 0;
}

.dl_drop-menu_options[data-position="right"] {
  right: 0;
}

.dl_drop-menu .dl_drop-menu_options .dl_drop-menu_option {
  display: flex;
  padding: 20px;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.dl_drop-menu .dl_drop-menu_options .dl_drop-menu_option:focus,
.dl_drop-menu .dl_drop-menu_options .dl_drop-menu_option:active {
  text-decoration: none;
}

.dl_drop-menu .dl_drop-menu_option_content {
  max-width: 88%;
}

.dl_drop-menu .dl_drop-menu_options .dl_drop-menu_option:hover {
  background: #f5f5f5;
}

.dl_drop-menu .dl_drop-menu_options .dl_drop-menu_option .dl_drop-menu_option_link {
  padding: unset;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--Title-Font-Color, #040404);
  font-family: 'Poppins';
}

.dl_drop-menu .dl_drop-menu_options .dl_drop-menu_option .dl_drop-menu_option_typo {
  color: #222;
  margin: unset;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  /* 133.333% */
  font-family: Arial;
}

.dl_drop-menu .dl_drop-menu_options .dl_drop-menu_option .dl_drop-menu_option_icon {
  width: 35px;
  height: 35px;
}


.dl_drop-menu.activeDrop .dl_drop-menu_btn .dl_drop-menu_btn_chevron-down_icon svg {
  transform: rotate(-180deg);
}

.dl_drop-menu.activeDrop .dl_drop-menu_options {
  display: block;
  opacity: 0;
  visibility: visible;
  z-index: 10;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 30px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}



@media screen and (max-width: 860px) {
  nav ul li .link {
    padding: 15px 18px;
  }

  nav .dl_drop-menu {
    margin: unset;
  }
}

/*======================================= for dropdown menu ============================================*/

/* keyframs for roll on text */
@keyframes rollDown {
  0% {
    top: 20px;
    opacity: 0;
  }

  11% {
    top: 0px;
    opacity: 1;
  }

  24% {
    top: 0px;
    opacity: 1;
  }

  33% {
    top: -20px;
    opacity: 0;
  }
}

@keyframes rollDown2 {
  33% {
    top: 20px;
    opacity: 0;
  }

  44% {
    top: 0px;
    opacity: 1;
  }

  57% {
    top: 0px;
    opacity: 1;
  }

  66% {
    top: -20px;
    opacity: 0;
  }
}


@keyframes rollDown3 {
  66% {
    top: 20px;
    opacity: 0;
  }

  77% {
    top: 0px;
    opacity: 1;
  }

  90% {
    top: 0px;
    opacity: 1;
  }

  99% {
    top: -20px;
    opacity: 0;
  }
}


/* @media screen and (min-width: 767px) {
    ._collage_container video {
      width: 45rem;
      height: 15rem;
    } 
  }
  
  @media screen and (max-width: 767px) {
    ._collage_container video {
      width: 100%;
      height: 15rem;
    } 
  } */

._link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._link svg {
  stroke: white;
}

.dl-block-aid {
  padding-bottom: 90px;
}

._collage_container {
  position: relative;
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 0 2vw;
}

.dl-block-aid-media {
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 0;
}

._column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

._post {
  position: relative;
  overflow: hidden;
  width: 100%;
}

._collage_container img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
}

._overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161616;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
  z-index: 2;
  border-radius: 5px;
}

._post:hover ._overlay {
  opacity: 0.5;
  cursor: pointer;
}




.gallery__item:hover ._overlay {
  opacity: 0.5;
  cursor: pointer;
}


.embed {
  overflow: hidden;
  padding-block-start: 100%;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
}

.embed--1-2 {
  padding-top: calc(100% / (1 / 2));
}

.embed--2-2 {
  padding-top: calc(100% / (2 / 2));
}

.embed--2-1 {
  padding-top: calc(100% / (2 / 1));
}

.embed--2-3 {
  padding-top: calc(100% / (2 / 3));
}

.embed--3-3 {
  padding-top: calc(100% / (3 / 3));
}

.embed--2-4 {
  padding-top: calc(100% / (2 / 4));
}

.embed>* {
  height: 100%;
  left: 0;
  object-fit: cover;
  padding: 0.25em;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Gallery  */

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(5, 1fr); */
}

@media screen and (max-width: 1280px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
  }
}

.gallery__item {
  height: fit-content;
  position: relative;
}

.gallery__item--h-1 {
  grid-column-end: span 1;
}

.gallery__item--h-2 {
  grid-column-end: span 2;
}

.gallery__item--h-3 {
  grid-column-end: span 3;
}

.gallery__item--v-1 {
  grid-row-end: span 1;
}

.gallery__item--v-2 {
  grid-row-end: span 2;
}

.gallery__item--v-3 {
  grid-row-end: span 3;
}


.error {
  color: red;
}

.transition-item {
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.7s ease-out;
  width: 100%;
}

.transition-item.visible {
  opacity: 1;
}

.dl-scroll-block {
  float: none;
  padding: 0;
}

#values.dl-scroll-block>.dl-block {
  float: none;
  float: none;
  padding-bottom: 0;
}

.message-hide-out {
  /* display: none; */
  opacity: 0;
  height: 0;
  transition: height 0.5s 0.5s, opacity 0.5s;
}

.message-visible-in {
  /* display: flex; */
  opacity: 1;
  height: auto;
  transition: height 0.5s 0.5s, opacity 0.5s;
}

.dl-service-card,
#values {
  padding: 140px 0;
}

#models.dl-scroll-block {
  /* float: left; */
  padding: 140px 0;
}

#models.dl-scroll-block h3 {
  /* padding-top: 140px; */
}

.hiring-process-dark .dl-block {
  background: var(--brand-color);
  color: #fff;
}

.hiring-process-dark .dl-block svg {
  filter: invert(1);
}

/* privacy-policy */
/**privacy-policy**/
.privacy-banner {
  position: relative;
}

.bottom-txt {
  margin-bottom: 0px;
  padding-left: 15px;
  position: absolute;
  bottom: 30px;
}

.bottom-txt p {
  color: #FFF;
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  opacity: .8;
}

.privacy-policy ul li, .privacy-policy ul li a{
  color:var(--brand-color);
  font-family: Arial,Helvetica,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.privacy-policy p, .privacy-policy ul li{
  opacity:1;
}

.privacy-points ul li > ul{
  list-style-type: disc;
  margin-bottom: 0;
}

.privacy-points p, .privacy-points ul{
  margin-bottom: 15px;
}

.privacy-points h2 {
  display: flex;
  gap: 15px;
  color: var(--brand-color);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 25px;
}

.privacy-points h3{
  color: var(--brand-color);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 15px;
}

.privacy-points table thead th {
  text-align: left;
  padding: 25px 16px;
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.privacy-points table thead {
  background: var(--brand-color);
}

.privacy-points table tbody td {
  color: var(--Base-Black, #000);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  vertical-align: top;
  padding: 16px;
  border-bottom: 1px solid #f4f4f4;
}

.privacy-points table {
  margin-bottom: 15px;
}

.privacy-points table tr td {
  width: 45%;
}

.privacy-points table tr td:last-child {
  width: 20%;
}

.privacy-points table tr td:first-child {
  width: 35%;
}